<template>
  <form-modal
    title="Manage Creadit"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
    size="sm"
  >
    <b-row>
      <form-input
        label="Credit Balance"
        v-model="form.creditBalance"
        data-vv-as="Credit Balance"
        :col="12"
        disabled
      />
      <form-select
        label="Action"
        v-model="form.action"
        v-validate="`required`"
        data-vv-as="Action"
        :options="options.action"
        :trackLable="`label`"
        :col="12"
      />
      <form-input-currency
        label="Amount"
        v-model="form.amount"
        v-validate="`required`"
        data-vv-as="Amount"
        :col="12"
      />
      <form-input-currency
        label="Credit Total"
        v-model="form.total"
        v-validate="`required`"
        data-vv-as="Credit Total"
        :col="12"
        disabled
      />
    </b-row>

    <div v-show="false">
      {{ _calcTotal }}
    </div>
  </form-modal>
</template>
<script>
import { mapActions, mapState } from "vuex"
export default {
  computed: {
    ...mapState("UserViews", ["reload"]),
    _calcTotal() {
      try {
        const {
          creditBalance,
          action: { value },
          amount,
          total,
        } = this.form
        let _return = 0
        if (value === "add credit") {
          _return = Math.abs(creditBalance) + Math.abs(amount)
        } else {
          _return = Math.abs(creditBalance) - Math.abs(amount)
        }
        this.form.total = _return

        return _return
      } catch (error) {
        return this.form?.creditBalance
      }
    },
  },
  data() {
    return {
      options: {
        action: [
          { label: "Add Credit", value: "add credit" },
          { label: "Reduce Credit", value: "reduce credit" },
        ],
      },
      form: {
        creditBalance: 0,
        action: "",
        amount: "",
        total: 0,
      },
    }
  },
  methods: {
    ...mapActions("GlobalUserData", ["ac_fetchCredit"]),
    show(creditBalance) {
      this.clearForm()
      this.form.creditBalance = creditBalance
      this.switch("show")
    },
    async handleSubmit() {
      try {
        const validatetor = await this.$validator.validateAll()
        if (!validatetor) {
          this.alertValidate()
          return ""
        }
        const form = this.cp(this.form)
        form.user_id = this.$route.params.user_id

        const resp = await this.api.post(
          `/api/admin/creditData/manageCreditData`,
          form
        )
        // GlobalUserData
        await this.ac_fetchCredit()

        this.alert.success({ text: "update credit" })

        this.reload.creditData = !this.reload.creditData
        this.switch("hide")
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
